import { Ref, ref } from 'vue'
import { Widget } from 'Widget/class/Widget'

import { getWidget } from 'lib/api/modules/streamer/freemium/widget'
import { getSetup } from 'lib/api/modules/widget/freemium'
import { Logger } from 'lib/helpers/handleLog'
import { ErrorMessage } from 'lib/types/error-message'
import { IFreemiumSetup } from 'lib/types/streamer-freemium/freemium-setup'
import { IFreemiumWidget } from 'lib/types/streamer-freemium/freemium-widget'

import { Chat } from './Chat'
import { Goal } from './Goal'
import { Poll } from './Poll'
import { Preview } from './Preview'
import { Supporters } from './Supporters'

export class Freemium {
  widget: Widget;
  isActive: Ref<boolean> = ref(false)

  preview = new Preview(this)
  goal = new Goal(this)
  poll = new Poll(this)
  supporters = new Supporters(this)
  chat: Chat

  readonly POLLING_INTERVAL = 1000 * 60 * 1
  pollingIntervalId: NodeJS.Timer | null = null

  constructor (widget: Widget) {
    this.widget = widget
    this.chat = new Chat(this)
  }

  async init () {
    try {
      this.isActive.value = this.widget.data.value.streamer?.freemium_active || false

      if (!this.isActive.value) {
        return
      }

      const { data, status } = await getSetup(this.widget.data.value.slug)

      if (!status) {
        Logger.critical(ErrorMessage.WIDGET_FREEMIUM_START, false)
        return
      }

      this.start(data)
    }
    catch (err) {
      Logger.critical(ErrorMessage.WIDGET_FREEMIUM_START, false)
    }
  }

  start (data: IFreemiumSetup) {
    this.setup(data)
    this.goal.loopCards()
    this.poll.initTimer()
    this.initPolling()
    this.chat.init()
  }

  private setup (data: IFreemiumSetup) {
    this.goal.setup(data.goal)
    this.poll.setup(data.poll)
    this.supporters.setup(data.support)
    this.chat.setup(data)
  }

  private async initPolling () {
    if (this.pollingIntervalId) {
      clearInterval(this.pollingIntervalId)
    }

    await this.fetctData()

    this.pollingIntervalId = setInterval(async () => {
      await this.fetctData()
    }, this.POLLING_INTERVAL)
  }

  private async fetctData () {
    const streamerId = this.widget.data.value.streamer?.user_id

    if (!streamerId) {
      return
    }

    const data = await getWidget(
      streamerId,
      this.goal.data.value ? this.goal.data.value?.id : null,
      this.poll.data.value ? this.poll.data.value?.id : null,
    ) as IFreemiumWidget
    this.update(data)
    this.supporters.loopStack()
  }

  private update (data: IFreemiumWidget) {
    this.goal.updateProgress(data.goalProgress)
    this.poll.updateResults(data.pollResults)
    this.supporters.updateLists(data.supporters)
  }
}
