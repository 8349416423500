import { render, staticRenderFns } from "./GoogleAdManager.vue?vue&type=template&id=04d07b65&scoped=true&"
import script from "./GoogleAdManager.vue?vue&type=script&setup=true&lang=ts&"
export * from "./GoogleAdManager.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./GoogleAdManager.vue?vue&type=style&index=0&id=04d07b65&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04d07b65",
  null
  
)

export default component.exports