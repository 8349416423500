import { Ref, ref } from 'vue'

import { Freemium } from '.'

export class Preview {
  freemium: Freemium
  isActive: Ref<boolean> = ref(false)
  readonly PREVIEW_DURATION = 1000 * 15

  constructor (freemium: Freemium) {
    this.freemium = freemium
  }

  show () {
    this.isActive.value = true
    this.freemium.goal.loopCards()
    this.freemium.supporters.loopStack()

    setTimeout(() => {
      this.isActive.value = false
      this.freemium.goal.loopCards()
    }, this.PREVIEW_DURATION)
  }
}
