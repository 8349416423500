import axios from 'axios'
import Echo from 'laravel-echo'
import Pusher, { AuthorizerCallback, Channel } from 'pusher-js'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.Pusher = Pusher

const url = `${process.env.VUE_APP_API_URL}broadcasting/auth`

export function pusher (slug: string) {
  const key = process.env.VUE_APP_PUSHER_APP_KEY
  const cluster = process.env.VUE_APP_PUSHER_APP_CLUSTER

  let subscribersCount = 0

  const echo = new Echo({
    broadcaster: 'pusher',
    key,
    cluster,
    forceTLS: true,
    authorizer: (channel: Channel) => ({
      authorize: async (socketId: number, callback: AuthorizerCallback) => {
        const params = {
          socket_id: socketId,
          channel_name: channel.name,
          slug: slug,
        }

        subscribersCount = channel.subscriptionCount as unknown as number

        const res = await axios.post(url, params)
        callback(null, res.data)
      },
    }),
  })

  return { echo, subscribersCount }
}
