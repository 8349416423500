import { Widget } from 'Widget/class/Widget'

import { ScreenshotApi } from 'lib/api/screenshot-instance'
import { Logger } from 'lib/helpers/handleLog'
import { ICreative, IDemoCreative, IPromoCreative } from 'lib/types/creative'
import { ErrorMessage } from 'lib/types/error-message'
import { Platform } from 'lib/types/platform'

import { SCREENSHOT_TIMEOUT } from '../constants/delays'

export class Screenshot {
  widget: Widget
  enabled = false
  private timer: NodeJS.Timeout | null = null

  constructor (widget: Widget) {
    this.widget = widget
  }

  enable () {
    if (this.enabled) return

    this.enabled = true

    this.timer = setTimeout(() => {
      this.enabled = false
    }, SCREENSHOT_TIMEOUT)
  }

  disable () {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.enabled = false
  }

  async makeScreenshot (
    creative: ICreative | IDemoCreative | IPromoCreative,
    attempt = 1,
  ) {
    if (!this.enabled) return

    if (this.widget.pusher.subscribersCount.value > 1) return

    if (!creative || !this.widget.data) return

    const data = this.getScreenshotData(creative)

    if (!data) return

    try {
      await ScreenshotApi.post(
        this.widget.data.value.platform,
        JSON.stringify(data),
        { showMessage: false },
      )
    }
    catch (err) {
      if (attempt > 0) {
        setTimeout(() => {
          this.makeScreenshot(creative, attempt - 1)
        }, 45 * 1000)
      }
      else {
        Logger.critical(ErrorMessage.WIDGET_MAKE_SCREENSHOT, false, err)
      }
    }
  }

  private getScreenshotData (creative: ICreative | IDemoCreative | IPromoCreative) {
    const widgetData = this.widget.data.value

    switch (widgetData.platform) {
      case Platform.TWITCH:
        return {
          nickname: widgetData.twitch?.nickname,
          ad: creative.slug,
          impression: creative.impressionSlug,
        }
      case Platform.YOUTUBE:
        return {
          slug: this.widget.streamInfo?.id,
          ad: creative.slug,
          impression: creative.impressionSlug,
        }
      case Platform.TROVO:
        return {
          nickname: widgetData.trovo?.nickname,
          ad: creative.slug,
          impression: creative.impressionSlug,
        }
      default:
        return null
    }
  }
}
