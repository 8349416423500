import { Currency } from 'lib/types/currency'
import { FreemiumPosition, IFreemiumGoal, IFreemiumPoll } from 'lib/types/streamer-freemium/freemium-setup'
import { IFreemiumSupporter } from 'lib/types/streamer-freemium/freemium-supporters'
import { IFreemiumWidget } from 'lib/types/streamer-freemium/freemium-widget'

export const demoGoal: IFreemiumGoal = {
  id: 1,
  title: 'New laptop',
  description: 'New laptop',
  currency: Currency.USD,
  publicTotal: false,
  total: 100,
  value: 25,
  status: true,
  position: FreemiumPosition.LEFT_TOP_CORNER,
}

export const demoPoll: IFreemiumPoll = {
  id: 2,
  question: 'What game should I play next?',
  answers: [
    'Answer 1',
    'Answer 2',
    'Answer 3',
    'Answer 4',
    'Answer 5',
  ],
  duration: 10,
  remainder_duration: 1000 * 60 * 10,
  votes_total: 100,
  position: FreemiumPosition.RIGHT_TOP_CORNER,
  end_condition: 'duration',
  max_votes: 100,
}

const supporters: IFreemiumSupporter[] = [
  {
    id: 1,
    name: 'KayAitchAyElEyeEl',
    updatedAt: '1',
  },
  {
    id: 2,
    name: 'Bundy_Burundi',
    updatedAt: '2',
  },
  {
    id: 3,
    name: 'Big_Rig',
    updatedAt: '3',
  },
]

export const demoResults: IFreemiumWidget = {
  goalProgress: 25,
  pollResults: [
    60, 24, 10, 6, 0,
  ],
  supporters: {
    top: supporters,
    topToday: supporters,
    recent: supporters,
  },
}
