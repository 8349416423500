export enum Positions {
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right'
}

export enum FreemiumMessagesFrequency {
  ONE_IN_FIFTEEN_MINS = 15,
  ONE_IN_THIRTY_MINS = 30,
  ONE_IN_HOUR = 60,
  DISABLED = 0,
}
export interface IFreemiumAlerts {
  support: {
    messageDuration: number
    position: Positions
  }
  goal: {
    position: Positions
  }
  poll: {
    position: Positions
  }
  chat: {
    enabled: boolean
  }
  chat_message_frequency: FreemiumMessagesFrequency
}
