import { ReverseMap, UrlType } from 'lib/types/base-types'
import { IStreamerPlatformPayload, IStreamerUserPayload } from 'lib/types/payloads/streamer'
import { PlatformType } from 'lib/types/platform'

export enum AdvertisingPosition {
  LEFT_TOP_CORNER = 'left_top_corner',
  LEFT_BOTTOM_CORNER = 'left_bottom_corner',
  RIGHT_TOP_CORNER = 'right_top_corner',
  RIGHT_BOTTOM_CORNER = 'right_bottom_corner',
}

export enum LeaderboardPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export enum AdvertisingMode {
  AUTO = 'auto',
  MANUAL = 'manual',
}

export type LeaderboardPositionType = ReverseMap<typeof LeaderboardPosition>
export type AdvertisingPositionType = ReverseMap<typeof AdvertisingPosition>
export type AdvertisingModeType = ReverseMap<typeof AdvertisingMode>

export interface IWidgetSettings {
  url: UrlType
  obsDockSlug: string
  slug: string
  platform: PlatformType
  boxSize: number
  referralPromotion: boolean
  stream: {
    delay: number
    enabled: boolean
  }
  advertising: {
    mode: AdvertisingModeType
    frequency: number
    position: AdvertisingPositionType
  }
  leaderboard: {
    position: LeaderboardPositionType
  }
  adManualEnabled: boolean
  ignoreCategories: Array<string>
  enabled: boolean
  botEnabled: boolean
  extensionEnabled: boolean
  nightbot: {
    twitch: {
      connected: boolean
      moderator: boolean
    }
    youtube: {
      connected: boolean
      moderator: boolean
    }
    trovo: {
      connected: boolean
      moderator: boolean
    }
  }
  relogin: boolean
  bsRequired: boolean
  allowAdultContent: boolean
  sspTextFrequency: number
}

export interface IWidget extends IWidgetSettings {
  streamer: IStreamerUserPayload | null
  twitch: IStreamerPlatformPayload | null
  youtube: IStreamerPlatformPayload | null
  trovo: IStreamerPlatformPayload | null
}
