import { ReverseMap } from 'lib/types/base-types'
import { ICreative, IPromoCreative } from 'lib/types/creative'
import { PlatformType } from 'lib/types/platform'

export interface IQueueCreative <T extends IPromoCreative | ICreative> {
  whenDisplay: number
  adSet: T[]
}

export interface IPusherErrorEvent {
  status: false
  messages: Array<{
    code: string
    field: string | null
    text: string
  }>
}

export interface IPusherSuccessEvent<T> {
  status: true
  data: T
}

export type PusherEvent<T> = { version: string } & (IPusherErrorEvent | IPusherSuccessEvent<T>)

export enum RequestTypes {
  BRAND_SAFETY = 'BRAND_SAFETY',
  BRAND_AWARENESS = 'BRAND_AWARENESS',
  REFERRAL_PROMO = 'REFERRAL_PROMO',
}

export type RequestType = ReverseMap<typeof RequestTypes>

export interface IPusherChatbotDisconnect {
  provider_id: number
  name: string
  avatar: string
  platform: PlatformType
}

export interface OBSStatus {
  idle: boolean
  streaming: boolean
  recording: boolean
  preview: boolean
}
