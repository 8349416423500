import { IdType } from 'lib/types/base-types'
import { Currency } from 'lib/types/currency'

export enum FreemiumPosition {
  LEFT_TOP_CORNER = 'top-left',
  LEFT_BOTTOM_CORNER = 'bottom-left',
  RIGHT_TOP_CORNER = 'top-right',
  RIGHT_BOTTOM_CORNER = 'bottom-right',
  DISABLED = 'disabled',
}

export interface IFreemiumGoal {
  id: IdType
  title: string
  description: string
  currency: Currency
  value: number
  total: number
  status: boolean
  publicTotal: boolean
  position: FreemiumPosition
}

export interface IFreemiumGoalModel {
  title: string
  description: string
  startValue: number
  totalValue: number
  publicTotal: boolean
}

export interface IFreemiumPoll {
  id: IdType
  question: string
  duration: number
  remainder_duration: number
  votes_total: number
  answers: string[]
  position: FreemiumPosition
  end_condition: 'duration' | 'votes'
  max_votes: number
}

export interface IFreemiumPollModel {
  question: string
  answers: Array<string>
  duration: number
  end_condition: 'duration' | 'votes'
  max_votes: number
}

export interface IFreemiumSupport {
  duration: number
  position: FreemiumPosition
}

export interface IFreemiumChat {
  enabled: boolean
}

export interface IFreemiumSetup {
  goal: IFreemiumGoal
  default_goal: {
    id: IdType
  }
  poll: IFreemiumPoll
  support: IFreemiumSupport
  income: {
    month: number
    total: number
  }
  chat: IFreemiumChat
  chat_message_frequency: number
}

export type IFreemiumGoalCard = 'progress' | 'topSupportersDay' | 'topSupportersAll' | 'qr'
