import { ref } from 'vue'
import Echo from 'laravel-echo'
import { Widget } from 'Widget/class/Widget'

import { payloadToWidgetAdapter } from 'lib/adapters/widgetPayloadAdapter'
import { pusher } from 'lib/pusher'
import * as pusherEvents from 'lib/pusher/constants/pusher-events'
import { ICreativesPayload, IDemoCreativePayload, IPromoCreativePayload, IRealDemoCreativePayload } from 'lib/types/payloads/creative'
import { IWidgetPayload } from 'lib/types/payloads/widget'
import { IFreemiumSetup } from 'lib/types/streamer-freemium/freemium-setup'

import { IPusherChatbotDisconnect, PusherEvent, RequestTypes } from '../types'

export class Pusher {
  widget: Widget
  channel = ''
  subscribersCount = ref<number>(0)

  constructor (widget: Widget) {
    this.widget = widget
  }

  init () {
    if (!this.widget.slug) return
    this.channel = `uplify.ads.${this.widget.slug}`

    const {
      echo,
      subscribersCount,
    } = pusher(this.widget.slug)

    this.subscribersCount.value = subscribersCount

    this.listenChannel(echo)
    this.listenPrivateChannel(echo)
  }

  private listenChannel (echo: Echo) {
    echo.channel(this.channel)
      .listen(pusherEvents.SUBSCRIPTION_COUNT, (event: {subscription_count: number}) => {
        this.subscribersCount.value = event.subscription_count
      })
  }

  private listenPrivateChannel (echo: Echo) {
    echo.private(this.channel)
      .listen(pusherEvents.WIDGET_UPDATED, (event: IWidgetPayload) => {
        this.widget.data.value = payloadToWidgetAdapter(event)
      })
      .listen(pusherEvents.WIDGET_REFRESH, (event: PusherEvent<null>) => {
        if (event.status) {
          window.location.reload()
        }
      })
      .listen(pusherEvents.MANUAL_LAUNCH, async () => {
        await this.widget.creativesLoop.startAd()
      })
      .listen(pusherEvents.AUTO_LAUNCH, (event: PusherEvent<ICreativesPayload>) => {
        this.widget.requestLoop.resetNextCall(RequestTypes.BRAND_AWARENESS)

        if (event.status) {
          this.widget.streamInfo = event.data.stream
          this.widget.preparer.prepareCreatives(event)
        }

        this.checkVersion(event.version)
      })
      .listen(pusherEvents.REFERRAL_LAUNCH, (event: PusherEvent<IPromoCreativePayload[]>) => {
        this.widget.requestLoop.resetNextCall(RequestTypes.REFERRAL_PROMO)
        if (event.status) {
          this.widget.preparer.preparePromoCreatives(event)
        }
      })
      .listen(pusherEvents.DEMO_REAL_LAUNCH, (event: PusherEvent<IRealDemoCreativePayload[]>) => {
        if (event.status) {
          this.widget.preparer.prepareDemoCreatives(event)
        }
      })
      .listen(pusherEvents.DEMO_LAUNCH, (event: PusherEvent<IDemoCreativePayload[]>) => {
        if (event.status) {
          this.widget.preparer.prepareDemoCreatives(event)
        }
      })
      .listen(pusherEvents.DEMO_REFERRAL_LAUNCH, (event: PusherEvent<IDemoCreativePayload[]>) => {
        if (event.status) {
          this.widget.preparer.prepareDemoCreatives(event)
        }
      })
      .listen(pusherEvents.FREEMIUM_PREVIEW, (event: PusherEvent<null>) => {
        if (event.status) {
          this.widget.freemium.preview.show()
        }
      })
      .listen(pusherEvents.FREEMIUM_SETUP, (event: PusherEvent<IFreemiumSetup>) => {
        if (event.status) {
          this.widget.freemium.start(event.data)
        }
      })
      .listen(pusherEvents.CHATBOT_DISCONNECT, (event: PusherEvent<IPusherChatbotDisconnect>) => {
        if (event.status) {
          this.widget.data.value.botEnabled = false
        }
      })
  }

  private checkVersion (version: string) {
    if (version !== this.widget.version) {
      if (this.widget.version === null) {
        this.widget.version = version
      }
      else {
        window.location.reload()
      }
    }
  }
}
