import { Widget } from 'Widget/class/Widget'
import { IPusherSuccessEvent } from 'Widget/types'

import { payloadDemoToCreativeAdapter, payloadToCreativeAdapter } from 'lib/adapters/creativePayloadAdapter'
import { Analytic } from 'lib/helpers/Analytic'
import { Logger } from 'lib/helpers/handleLog'
import {
  ICreativePayload,
  ICreativesPayload,
  IDemoCreativePayload,
  IPromoCreativePayload,
  IRealDemoCreativePayload,
} from 'lib/types/payloads/creative'

import { Ssp } from './Ssp'

type DemoCreativeType = IDemoCreativePayload | IRealDemoCreativePayload

export class Preparer {
  widget: Widget;
  ssp: Ssp | null = null

  constructor (widget: Widget) {
    this.widget = widget
    this.ssp = new Ssp(widget)
  }

  async prepareCreatives (event: IPusherSuccessEvent<ICreativesPayload>) {
    const { items: creatives, stream } = event.data

    Logger.debug('event.data', event.data)

    const resolvedCreatives = await this.ssp?.prepareSspCreatives(creatives, stream) ?? []

    const ad = resolvedCreatives.map((item: ICreativePayload) => {
      const creative = payloadToCreativeAdapter(item, stream)
      Analytic.ga('ecommerce:addItem', {
        id: creative.uuid,
        name: creative.slug,
        price: creative.viewersCount,
        quantity: '1',
      })
      return creative
    })
    this.widget.creativesLoop.addAd(ad)
  }

  prepareDemoCreatives (event: IPusherSuccessEvent<DemoCreativeType[]>) {
    const ad = event.data.map((item: DemoCreativeType) => payloadDemoToCreativeAdapter(item))
    this.widget.creativesLoop.addDemo(ad)
  }

  preparePromoCreatives (event: IPusherSuccessEvent<IPromoCreativePayload[]>) {
    const ad = event.data.map((item: IPromoCreativePayload) => payloadDemoToCreativeAdapter(item))
    this.widget.creativesLoop.addAd(ad)
  }
}
