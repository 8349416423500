import { demoResults } from 'components/freemium/freemium-mock'
import { getRandomEmoji } from 'lib/helpers/getRandomEmoji'
import { IStreamerFreemiumTopSupporters } from 'lib/types/payloads/streamer-freemium'
import { FreemiumPosition, IFreemiumSetup, IFreemiumSupport } from 'lib/types/streamer-freemium/freemium-setup'
import { IFreemiumSupporter, IFreemiumWidgetSupporter } from 'lib/types/streamer-freemium/freemium-supporters'
import { IFreemiumWidget, IFreemiumWidgetSupporters } from 'lib/types/streamer-freemium/freemium-widget'
import { debounce } from 'lodash'
import { Ref, ref } from 'vue'

import { Freemium } from '.'

const defaultTopSupporters: IFreemiumWidgetSupporters = {
  top: [],
  topToday: [],
}
export class Supporters {
  freemium: Freemium
  data: Ref<IFreemiumSupport|null> = ref(null)
  stack: Ref<IFreemiumWidgetSupporter[]> = ref([])
  recent: Ref<IFreemiumSupporter[]> = ref([])
  top: Ref<IFreemiumWidgetSupporters> = ref(defaultTopSupporters)
  position: Ref<FreemiumPosition> = ref(FreemiumPosition.LEFT_BOTTOM_CORNER)
  positionAlign: 'top' | 'bottom' = 'bottom'
  isAdding: Ref<boolean> = ref(false)
  isFirstRemoving: Ref<boolean> = ref(false)
  private readonly TRANSITION_MS = 300
  private readonly MAX_IN_STACK = 5
  private readonly ADD_INTERVALS = [1000 * 3, 1000 * 5]
  private readonly VISIBLE_MS = 1000 * 7

  constructor (freemium: Freemium) {
    this.freemium = freemium
  }

  setup (data: IFreemiumSetup['support']) {
    this.data.value = data
    this.position.value = data.position

    switch (this.position.value) {
    case FreemiumPosition.LEFT_BOTTOM_CORNER:
    case FreemiumPosition.RIGHT_BOTTOM_CORNER:
      this.positionAlign = 'bottom'
      return
    case FreemiumPosition.LEFT_TOP_CORNER:
    case FreemiumPosition.RIGHT_TOP_CORNER:
      this.positionAlign = 'top'
    }
  }

  updateLists (data: IFreemiumWidget['supporters']) {
    this.recent.value = [...this.recent.value, ...data.recent]
    this.top.value = {
      top: data.top,
      topToday: data.topToday,
    }
  }

  private addToRecent (supporter: IFreemiumWidgetSupporter) {
    this.isAdding.value = true
    if (this.positionAlign === 'bottom') {
      this.stack.value.push(supporter)
    }
    if (this.positionAlign === 'top') {
      this.stack.value.unshift(supporter)
    }

    setTimeout(() => (this.isAdding.value = false), this.TRANSITION_MS)

    if (this.stack.value.length >= this.MAX_IN_STACK + 1) {
      setTimeout(() => (this.isFirstRemoving.value = true), this.TRANSITION_MS)
      setTimeout(() => this.removeFromRecent(), this.TRANSITION_MS * 2)
    }
    else {
      setTimeout(() => this.removeFromRecent(), this.VISIBLE_MS)
    }
  }

  private removeFromRecent () {
    if (this.positionAlign === 'bottom') {
      this.stack.value.shift()
    }
    if (this.positionAlign === 'top') {
      this.stack.value.pop()
    }
    this.isFirstRemoving.value = false
  }

  loopStack () {
    const recentSupporters = this.freemium.preview.isActive.value ? demoResults.supporters.recent : this.recent.value

    if (!recentSupporters || !recentSupporters.length) {
      return
    }

    const getRandomInterval = () => this.ADD_INTERVALS[Math.floor(Math.random() * this.ADD_INTERVALS.length)]

    const inteval = setInterval(() => {
      debounce(() => {
        if (!recentSupporters || !recentSupporters.length) {
          clearInterval(inteval)
          return
        }

        const supporter = recentSupporters.shift()

        if (!supporter) {
          clearInterval(inteval)
          return
        }

        this.addToRecent({
          emoji: getRandomEmoji(),
          nickname: supporter.name,
          anonymous: Boolean(supporter.name && supporter.name !== ''),
          createdAt: supporter?.updatedAt,
        })
      }, getRandomInterval())()
    }, 1000)
  }
}
