export const WIDGET_UPDATED = '.widget.params.updated'
export const WIDGET_REFRESH = '.widget.refresh'
export const MANUAL_LAUNCH = '.manual.launch'
export const AUTO_LAUNCH = '.auto.launch'
export const DEMO_REAL_LAUNCH = '.demo.real.launch'
export const DEMO_LAUNCH = '.demo.launch'
export const DEMO_REFERRAL_LAUNCH = '.demo.referral.launch'
export const FETCHING_AD = '.fetching-ad'
export const REFERRAL_LAUNCH = '.referral.launch'
export const CHATBOT_MESSAGE_SENT = '.chatbot-message-sent'
export const FREEMIUM_PREVIEW = '.freemium-preview'
export const FREEMIUM_SETUP = '.freemium.params.setup'
export const CHATBOT_DISCONNECT = '.chatbot.disconnected'
export const SUBSCRIPTION_COUNT = '.pusher:subscription_count'
